import React, { useState, useEffect } from 'react'
import { colors, TablePagination } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { mode_store_redux } from '../Mode_Redux'
import { style } from '@mui/system/Stack/createStack'
import { color } from '@mui/system'

const Paginate = (props) => {
  const [page, set_page] = useState(0);
  const [rows, set_rows] = useState(10);
  const [mode, set_mode] = useState(mode_store_redux.getState());
  // const mode = mode_store_redux.getState();
  const notify_object_length = props.notify_object_length;

  // const theme = createTheme({       
  //   palette: {
  //     primary: {
  //       main: '#AAAAAA',           
  //     },
  //   },
  //   components: {
  //     // Name of the component
  //     TablePagination: {
  //       styleOverrides: {
  //         // Name of the slot
  //         root: {
  //           // Some CSS
  //           fontSize: '100px',
  //         },
  //       },
  //     },
  //   },
  // });

  const onPageChange = (event,page)=>{
    set_page(page);
    console.log('page in paginate-', page)
    props.on_page_change(page);
  }
  
  const rowsPerPageChange = (event)=>{
    let row_value = event.target.value;
    set_rows(row_value);
    console.log('rows in paginate-', row_value)
    props.on_rows_change(row_value);
  }
  useEffect(()=>{
    (mode_store_redux.getState() == undefined ||
    mode_store_redux.getState() == true
    )
    ?
    set_mode(true)
    :
    set_mode(false);
},[])

  return (
    <div>
      {/* <ThemeProvider theme={theme}> */}
        <TablePagination
         component={'div'}
         color='#FFFFFF'
         size="small"
         count={notify_object_length}
         page={page}
        //  rowsPerPage={10}
         rowsPerPage={rows}
         onRowsPerPageChange={rowsPerPageChange}
         onPageChange={onPageChange}
         sx={ mode == true
          ?
            {
            color: '#1C2C40',
            }
          :
            {
              color: '#FFFFFF',
            }
        }
        backIconButtonProps={mode==false ?{
          style : { color:"#FFFFFF"}
        }:   {}
      }
        nextIconButtonProps={mode==false ? {
          style : { color:"#FFFFFF"}
        }: {}}
        />
      {/* </ThemeProvider> */}
    </div>
  )
}

export default Paginate 