import './App.css';
import './Login_component/Login.css'
import './Home_component/Home.css';
import './PerNotification_component/PerNotification.css';
// import './Push_component/Push.css'
import './Push_component/Create_Push.css'
import './Paginate_component/Paginate.css'
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'

import Login from './Login_component/Login';
import Home from './Home_component/Home';

function App() {
  document.title = "Notification Portal"
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path='/' element={<Login/>} />
          <Route path='/home' element={<Home/>} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
