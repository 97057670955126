import { legacy_createStore } from "redux"

const mode_state_object = {
    mode : true
}
function reducer(state = mode_state_object.mode, action){
    switch(action.type){
        case 'LIGHT_MODE' :
            state = true;
            return state;

        case 'DARK_MODE' :
            state = false;
            return state;
    }
}
export const mode_store_redux = legacy_createStore(reducer);
export default reducer; 