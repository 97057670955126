import React, { useState, useEffect } from 'react'
import { mode_store_redux } from '../Mode_Redux';

const PerNotification = (props) => {   

    // const [mode, set_mode] = useState(mode_store_redux.getState());
    const [mode, set_mode] = useState(true);
    let notify_object = props.notify_object;
    console.log('per object',notify_object)

    let [notify_object_state, set_notify_object_state] = useState(notify_object);

    // notify_object_state.length < 1 
    // ?
    // notify_object =  props.notify_object
    // :
    // notify_object = notify_object_state
        
    useEffect(()=>{
        (mode_store_redux.getState() == undefined ||
        mode_store_redux.getState() == true
        )        ?
        set_mode(true)
        :
        set_mode(true);   
     },[])

     useEffect(()=>{
        console.log("after effect state-",notify_object_state);
     },[notify_object_state])

  return (
    <div className='perNotificationHead'>
        {
            notify_object_state.map((item,value)=>{
                return(
                    <div className='perNotify'
                        key={item.sno} 
                        // style={ (item.sno % 2 > 0 && mode == true)
                        //     ?
                        //     {backgroundColor:'#F9FAFC'}
                        //     :
                        //     (item.sno % 2 == 0 && mode == true)
                        //     ?
                        //     {backgroundColor:'#FFFFFF'}   
                        //     :                
                        //     (item.sno % 2 > 0 && mode == false)
                        //     ?
                        //     {backgroundColor:'#1D2229'}
                        //     :                             
                        //     {backgroundColor:'#313843'}
                        // }
                        style={{
                            backgroundColor:"#FFFFFF"
                        }}
                    >
                        {/* <div className='per_sno'
                        style={mode==true ?  {}  : {color:'#FFFFFF'}}
                        >{item.sno}</div> */}
                        <div className='per_sno'
                        style={mode==true ?  {}  : {color:'#FFFFFF'}}
                        >
                            <button
                            onClick={()=>{
                                console.log(value);
                                notify_object_state.splice(value,1);
                                console.log("notify object",notify_object_state);
                                // notify_object = notify_object;
                                set_notify_object_state(notify_object);
                                props.notify_object_func(notify_object_state);
                            }}
                            >Delete
                            </button>
                        </div>
                        <div className='per_notify'
                        style={mode==true ?  {}  : {color:'#FFFFFF'}}
                        >{item.title}</div>
                        <div className='per_desc'
                        style={mode==true ?  {}  : {color:'#FFFFFF'}}
                        >{item.description}</div>
                        <div className='per_img'
                        style={mode==true ?  {}  : {color:'#FFFFFF'}}
                        >{item.image}</div>
                        <div className='per_sent'
                        style={mode==true ?  {}  : {color:'#FFFFFF'}}
                        >{item.total_sent}</div>
                        <div className='per_created'
                        style={mode==true ?  {}  : {color:'#FFFFFF'}}
                        >{item.created_on}</div>
                    </div>
                )
            })
        }
    </div>
  )
}

export default PerNotification