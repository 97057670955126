import React from 'react'

const NotifyObject = () => {
    const notify_object = [
        {
            // sno: 1,
            title: 'Notification Title 1',
            description: 'Description',
            image: 'Image',
            total_sent: 'SENT',
            created_on: new Date().toDateString()
        },
        {
            // sno: 2,
            title: 'Notification Title 2',
            description: 'Description',
            image: 'Image',
            total_sent: 'SENT',
            created_on: new Date().toDateString()
        },
        {
            // sno: 3,
            title: 'Notification Title 3',
            description: 'Description',
            image: 'Image',
            total_sent: 'SENT',
            created_on: new Date().toDateString()
        },
        {
            // sno: 4,
            title: 'Notification Title 4',
            description: 'Description',
            image: 'Image',
            total_sent: 'SENT',
            created_on: new Date().toDateString()
        },
        {
            // sno: 5,
            title: 'Notification Title 5',
            description: 'Description',
            image: 'Image',
            total_sent: 'SENT',
            created_on: new Date().toDateString()
        }
        // {
        //     sno: 6,
        //     title: 'Notification Title',
        //     description: 'Description',
        //     image: 'Image',
        //     total_sent: 'SENT',
        //     created_on: new Date().toDateString()
        // },
        // {
        //     sno: 7,
        //     title: 'Notification Title',
        //     description: 'Description',
        //     image: 'Image',
        //     total_sent: 'SENT',
        //     created_on: new Date().toDateString()
        // },
        // {
        //     sno: 8,
        //     title: 'Notification Title',
        //     description: 'Description',
        //     image: 'Image',
        //     total_sent: 'SENT',
        //     created_on: new Date().toDateString()
        // },
        // {
        //     sno: 9,
        //     title: 'Notification Title',
        //     description: 'Description',
        //     image: 'Image',
        //     total_sent: 'SENT',
        //     created_on: new Date().toDateString()
        // },
        // {
        //     sno: 10,
        //     title: 'Notification Title',
        //     description: 'Description',
        //     image: 'Image',
        //     total_sent: 'SENT',
        //     created_on: new Date().toDateString()
        // },
        // {
        //     sno: 11,
        //     title: 'Notification Title',
        //     description: 'Description',
        //     image: 'Image',
        //     total_sent: 'SENT',
        //     created_on: new Date().toDateString()
        // },
        // {
        //     sno: 12,
        //     title: 'Notification Title',
        //     description: 'Description',
        //     image: 'Image',
        //     total_sent: 'SENT',
        //     created_on: new Date().toDateString()
        // },
        // {
        //     sno: 13,
        //     title: 'Notification Title',
        //     description: 'Description',
        //     image: 'Image',
        //     total_sent: 'SENT',
        //     created_on: new Date().toDateString()
        // }
    ]
  return (notify_object)
}

export default NotifyObject