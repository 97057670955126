import { Switch, ThemeProvider } from '@mui/material'
import { createTheme } from '@mui/material/styles'
import phone_svg from '../images-notification-portal/phone_svg.svg'
import React, { useEffect, useState } from 'react'
import { mode_store_redux } from '../Mode_Redux'

const Create_Push = (props) => {

    const[singleUser, set_singleUser] = useState(true);
    const[multipleUser, set_multipleUser] = useState(false);
    const[mode, set_mode] = useState(mode_store_redux.getState())

    const theme = createTheme({       
        palette: {
          primary: {
            main: '#7C35E4',           
          },
        },
      });

    const singleUserChecked = ()=>{
        set_multipleUser(false);
        return set_singleUser(true);
    }
    const multipleUserChecked = ()=>{
        set_singleUser(false);
        return set_multipleUser(true);
    }

    useEffect(()=>{
        (mode_store_redux.getState() == undefined ||
        mode_store_redux.getState() == true
        )
        ?
        set_mode(true)
        :
        set_mode(false);
        },[])

  return (
    <div 
        className='create_push_page'
        style={mode==true ?  {}  : {backgroundColor:'#0B161E'}}
    >
        <div 
            className='create_push_panel'
            style={mode==true ?  {}  : {backgroundColor:' #1D2229'}}
        >
            <div className='create_push_notifHead'>
                <p
                  style={mode==true ?  {}  : {color:'#FFFFFF'}}
                >
                    Push Notification
                </p>
                    <ThemeProvider theme={theme}>
                            <Switch 
                                color='primary'
                                defaultChecked = 'true'/>
                    </ThemeProvider>  
            </div>

            <div className='create_push_notifDetails'>
                <div className='create_push_notifInputs'>
                    <div className='create_push_notifTitle'>
                        <label                     
                            style={mode==true ?  {}  : {color:'#FFFFFF'}}
                        >
                            TITLE
                        </label>
                        <input
                            type={'text'}
                            placeholder={'Enter Title'} 
                            style={mode==true ?  {}  : {backgroundColor:' #1D2229',color:'#FFFFFF'}}
                        >                
                        </input>
                    </div> 

                    <div className='create_push_notifDesc'>
                    <label
                         style={mode==true ?  {}  : {color:'#FFFFFF'}}
                    >
                        DESCRIPTION
                        </label>
                    <textarea
                        type={'text'}
                        placeholder={'Enter Description'} 
                        style={mode==true ?  {}  : {backgroundColor:' #1D2229',color:'#FFFFFF'}}
                    >                
                    </textarea>
                    </div>   

                    <div className='create_push_notifImage'>
                    <label
                        style={mode==true ?  {}  : {color:'#FFFFFF'}}
                    >
                        Image
                    </label>
                    <div>
                        <label htmlFor='uploadFile'>
                            Upload Image
                        </label>
                        <input
                            id='uploadFile'
                            type={'file'}
                            style={mode==true ?  {}  : {backgroundColor:' #1D2229',color:'#FFFFFF'}}                             
                        >                
                        </input>
                    </div>                    
                </div>
                
                <div className='create_push_notifUrl'>
                    <label
                        style={mode==true ?  {}  : {color:'#FFFFFF'}}
                    >
                        URL
                    </label>
                    <input
                        type={'text'}
                        placeholder={'Paste URL'} 
                        style={mode==true ?  {}  : {backgroundColor:' #1D2229',color:'#FFFFFF'}}
                    >                
                    </input>
                </div>

                <div className='create_push_notifTarget'>
                    <label
                        style={mode==true ?  {}  : {color:'#FFFFFF'}}
                    >
                        TARGET AUDIENCE
                    </label>
                    <div className='radioSingleMultiple'>
                        <div className='radioSingle'>
                            <input 
                                className='radioSingleButton'
                                type={'radio'}
                                onChange={singleUserChecked}
                                checked={singleUser}
                                color='red'
                                style={mode==true ?  {}  : {backgroundColor:' #1D2229'}}
                                ></input>
                            <p
                                style={mode==true ?  {}  : {color:'#FFFFFF'}}
                            >
                                Single User
                            </p>
                        </div>

                        <div className='radioMultiple'>
                            <input
                                 className='radioMultipleButton'
                                 type={'radio'}
                                 onChange={multipleUserChecked}
                                 checked={multipleUser}
                                //  style={{color:"#7C35E4"}}
                            ></input>
                            <p
                                style={mode==true ?  {}  : {color:'#FFFFFF'}}
                            >
                                Multiple User
                            </p>
                        </div>
                    </div>
                    <input
                        type={'text'}
                        placeholder={'Enter Mail Id'} 
                        style={mode==true ?  {}  : {backgroundColor:' #1D2229',color:'#FFFFFF'}}
                    >                
                    </input>
                </div>

                </div>

                {/* <div className='phonebox'> */}
                <div 
                    className='phone'
                    style={mode==true ?  {}  : {backgroundColor:'#313843'}}
                >
                <img
                    className='phoneImg'
                    src={phone_svg}
                />
                <div className='phoneNotif'>
                    <div className='alertNotif'>
                        <p>Alert Notification</p>
                        <p>Clear All</p>
                    </div>
                    <div className='cardNotif'
                         style={mode==true ?  {}  : {backgroundColor:'#DBDBDC'}}
                    >
                        <div className='cardHead'>
                            <p>Notif Name</p>
                            <p>5 sec ago</p>
                        </div>
                        <div className='cardTitle'>
                            <p>Title</p>                            
                        </div>
                        <div className='cardDesc'>
                            <p>Description........................</p>                            
                        </div>
                    </div>
                </div>
            </div>
            {/* </div> */}

            </div>

            <div className='create_push_notifButtons'
                style={mode==true ?  {}  : {backgroundColor:' #1D2229'}}
            >
            <button 
                className='cancelBtn'
                onClick={()=>{
                    props.cancel_push_notif();
                }}
                style={mode==true ?  {}  : {backgroundColor:'#1D2229'}}
            >
                Cancel
            </button>
            <button className='sendBtn'>Send</button>
            </div>
        </div>
    </div>
  )
}

export default Create_Push