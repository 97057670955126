import React, { useEffect, useState } from 'react'
import signIn_img_png from '../images-notification-portal/signIn_img_png.png'
import eye from '../images-notification-portal/eye.png'
import Home from '../Home_component/Home'
import { useNavigate } from 'react-router-dom'
import { mode_store_redux } from '../Mode_Redux'

const Login = () => {
  let navigate = useNavigate(null);
  const [mode, set_mode] = useState(true);
  const [email, set_email] = useState('');
  const [pass, set_pass] = useState('');
  const [checkbox, set_checkbox] = useState(false);
  const [passShow, set_passShow] = useState(true);
  const [eye_press, set_eye_press] = useState(true);

  mode_store_redux.subscribe(()=>{
    set_mode(mode_store_redux.getState());
    console.log("subscribe mode--",mode)
  })

  function dispatch_light(){
    mode_store_redux.dispatch({
      type: "LIGHT_MODE"
    })
    set_eye_press(!eye_press);
  }
  function dispatch_dark(){
    mode_store_redux.dispatch({
      type: "DARK_MODE"
    })
    set_eye_press(!eye_press);
  }

  // useEffect(()=>{
  //   dispatch_light();
  // },[])

  return (
    <div className='loginPage'
         style={mode==true ?  {}  : {backgroundColor:'#0B161E'}}
    >
        <div className='loginImageDiv'>
            <img 
                className='loginImage'
                src={signIn_img_png}
            />
        </div>

        <div className='signInBox'>
          <div className='signInDetails'>
            <p
              style={mode==true ? {}  : {color:'#FFFFFF'}}
            >
              SIGN IN</p>
              <input
                className='email' 
                type={'email'}
                style={mode==true ? {}  : {backgroundColor:'#0B161E',color:'#FFFFFF'}}
                placeholder="Email Address"
                onChange={(e)=>{
                  set_email(e.target.value);
                }}
              >                  
              </input>
              <div className='passwordDiv'>
                <input
                  className='password' 
                  type={passShow ? 'password' : 'text'}
                  style={mode==true ?{}:{backgroundColor:'#0B161E',color:'#FFFFFF'}}
                  placeholder="Password"
                  onChange={(e)=>{
                    set_pass(e.target.value);
                  }}
                >    
                </input>
                <img 
                  className='eye_image'
                  src={eye}
                  onClick={()=>{ 
                    // eye_press == true 
                    // ?
                    // dispatch_light()                    
                    // :
                    // dispatch_dark()
                    set_passShow(!passShow);
                }}
                />
              </div>
              <div className='checkboxDiv'>
                <input 
                  className='checkbox'
                  type={'checkbox'}
                >                
                </input>
                <div 
                  className='remember'
                  style={mode==true ? {}  : {color:'#FFFFFF'}}
                  >
                    Remember Me</div>
                <div 
                  className='forgot'
                  style={mode==true ? {}  : {color:'#7A31E3'}}
                >
                  Forgot Password?
                </div>                
              </div>
              <div>
                <button 
                  className='signInBtn'
                  onClick={()=>{
                    email.length>0 && pass.length>0
                    ?
                    navigate('/home')
                    :
                    alert("Please enter Email Id and Password")
                  }}
                >
                  SIGN IN
                </button>
              </div>
          </div>
        </div>

    </div>
  )
}

export default Login