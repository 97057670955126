import React, { useState, useEffect } from 'react'
import NotifyObject from '../NotifyObject'
import search from '../images-notification-portal/search.png'
import Paginate from '../Paginate_component/Paginate'
import PerNotification from '../PerNotification_component/PerNotification'
import Create_Push from '../Push_component/Create_Push'
import { useNavigate } from 'react-router-dom'
import { mode_store_redux } from '../Mode_Redux'

const Home = () => {

   

    const navigate = useNavigate(null);    
    const notify_object = NotifyObject();
    const [create_push_notif, set_create_push_notif] = useState(false);
    const [rows_per_page, set_rows_per_page] = useState(10);
    const [current_page, set_current_page] = useState(0);
    const [current_notify_object, set_current_notify_object] = useState(notify_object.slice(0,10));
    const [mode, set_mode] = useState(mode_store_redux.getState());

    const notify_object_length = notify_object.length;

    console.log(mode);

    function notify_object_func(values){
        console.log(values)
        // set_current_notify_object(values)
    }

    const cancel_push_notif = ()=>{
        set_create_push_notif(false);
        set_current_notify_object(notify_object.slice(0,10));
    }

    const on_rows_change = (rows)=>{
        set_rows_per_page(rows);
        console.log('home page,rows_change', current_page, rows)
        let object = notify_object.slice(((current_page*rows_per_page)),(((current_page*rows_per_page))+rows_per_page));
        set_current_notify_object(object);
    }

    const on_page_change = (page)=>{
        set_current_page(page);
        console.log('in home, page, rowsperpage-',page, rows_per_page);
        // let object = notify_object.slice(((page*10)),(((page*10))+10));
        let object = notify_object.slice(((page*rows_per_page)),(((page*rows_per_page))+rows_per_page));
        set_current_notify_object(object);
    }
    useEffect(()=>{
        let object = notify_object.slice(((current_page*rows_per_page)),(((current_page*rows_per_page))+rows_per_page));
        set_current_notify_object(object);
    },[rows_per_page, current_page])

    useEffect(()=>{
    (mode_store_redux.getState() == undefined ||
    mode_store_redux.getState() == true
    )
    ?
    set_mode(true)
    :
    set_mode(false);
    },[])
    
  return (
    <div>
        <div className='headBar'>
            <button 
                className='profileImage'
                onClick={()=>{ 
                    // set_mode(!mode);
                }}
            ></button>
            <button
                 className='logoutBtn'
                 onClick={()=>{
                    navigate('/');
                 }}
            >
                Logout
            </button>
        </div>

        { 
            create_push_notif === false 
            ?
        <div className='notificationPanel'
        style={mode==true ?  {}  : {backgroundColor:'#0B161E'}}
        >
            <div 
                className='panelHead'
                style={mode==true ?  {}  : {backgroundColor:'#1D2229'}}
            >
               <p className='headTitle'>
                Recent Push Notification
                </p>
               <div className='search_pushDiv'>
                    <div 
                        className='searchDiv'
                        style={mode==true ?  {}  : {backgroundColor:'#313843'}}
                    >
                        <img
                            className='searchImage' 
                            src={search}
                            style={mode==true ?  {}  : {backgroundColor:'#FFFFFF'}}
                        />
                        <input
                            className='searchBar'
                            type={'text'}
                            placeholder={'Search...'}
                            style={mode==true ?  {}  : {color:'#FFFFFF'}}
                        >                            
                        </input>
                    </div>
                    <div 
                        className='pushDiv'
                        style={mode==true ?  {}  : {backgroundColor:'#7A31E3'}}
                        onClick={()=>{
                            set_create_push_notif(true);
                        }}
                    >
                        <p 
                          className='pushImage'
                          style={mode==true ?  {}  : {color:'#FFFFFF'}}
                        >
                            +
                        </p>
                        <p
                          className='pushBtn'
                          style={mode==true ?  {}  : {color:'#FFFFFF'}}
                        >
                            Create Push Notification
                        </p>
                    </div>
                </div>
            </div>
                <div
                    className='panelTitles'
                    style={mode==true ?  {borderColor:'#C0CCDA'} : {backgroundColor:'#1D2229', borderColor:'#3C4858'}}
                >
                    {/* <div className='sno'
                    style={mode==true ?  {}  : {color:'#FFFFFF'}}
                    >
                        S.No.
                    </div> */}
                    <div className='notify'
                    style={mode==true ?  {}  : {color:'#FFFFFF'}}
                    >Notification Title</div>
                    <div className='desc'
                    style={mode==true ?  {}  : {color:'#FFFFFF'}}
                    >Description</div>
                    <div className='img'
                    style={mode==true ?  {}  : {color:'#FFFFFF'}}
                    >Image</div>
                    <div className='sent'
                    style={mode==true ?  {}  : {color:'#FFFFFF'}}
                    >Total Sent</div>
                    <div className='created'
                    style={mode==true ?  {}  : {color:'#FFFFFF'}}
                    >Created On</div>
                </div>

            <div 
                className='panelList'
                style={mode==true ?  {} : {background:'#1D2229'}}
            >
                {/* { 
                create_push_notif === false 
                ? */}
                    <PerNotification 
                        notify_object={current_notify_object}
                        
                        // notify_object={notify_object_func()}
                    /> 
                {/* :
                    <Push/>
                } */}
            </div>

            <div 
                className='panelPagination'
                style={mode==true ?  {borderColor:'#C0CCDA'}  : {backgroundColor:'#1D2229', borderColor:'#3C4858'}}
            >
                <Paginate 
                    notify_object_length={notify_object_length}
                    on_rows_change={on_rows_change}
                    on_page_change={on_page_change}
                />
            </div>
        </div>

        :
        
        // <Push cancel_push_notif={cancel_push_notif} />
        <Create_Push cancel_push_notif={cancel_push_notif}/>
    }
    </div>
  )
}

export default Home